import './App.css';
import {Col, Container, Row} from "react-bootstrap";
import { Analytics } from '@vercel/analytics/react';
import AdsComponent from "./AdsComponent";

function App() {
    console.log("@payed your mom")
  return (
      <div className="App">
        <Container >
            <AdsComponent dataAdSlot='X7XXXXXX5X' />
            <Row style={{
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              padding: 16,
          }}>
            <Col style={{
                color: 'grey',
                textAlign: 'center'}}>
                Bianca is the most beautiful princess  {'<3'}
            </Col>
          </Row>
            <Row style={{marginTop: 16}}>
                <Col>
                    Hey
                </Col>
            </Row>
        </Container>
        <Analytics />
      </div>
  );

}

export default App;
